import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = 'products/options/template';

function getFilter(data) {
  return storeRequest.get(path, { params: { ...data } });
}

function getFilterById(data) {
  return storeRequest.get(path + '/' + data);
}

function postFilter(data) {
  return storeRequest.post(path, data);
}

function putFilter(data, id) {
  return storeRequest.put(path + `/${id}`, data);
}

function deleteFilter(data) {
  return storeRequest.delete(path + '/' + data);
}

export const menuFilterApi = {
  getFilter,
  getFilterById,
  postFilter,
  putFilter,
  deleteFilter,
};
