<template>
  <div class="product-page">
    <redirect class="mb-5" title="Menu Filter" route="website.menufilter" />
    <v-row no-gutters>
      <v-col sm="12" class="page-header">
        <span class="page-title">{{ $t('Create Options') }}</span>
      </v-col>
    </v-row>
    <!-- là bảng create Variant size color -->
    <div class="elevation-1 px-3">
      <v-row no-gutters class="mt-0">
        <v-col sm="2" class="">
          <v-radio-group v-model="filterType">
            <v-radio
              off-icon="mdi-checkbox-blank-outline"
              on-icon="mdi-checkbox-marked"
              color="primary"
              value="variants"
              label="Variant"
              @change="turnOffCreate()"
            >
            </v-radio>
            <transition name="slide-key">
              <v-radio-group class="pl-8" v-if="filterType == 'variants'" v-model="variantFilterType">
                <v-radio color="primary" @change="turnOffCreate()" value="Color" label="Color"></v-radio>
                <v-radio color="primary" @change="turnOffCreate()" value="Size" label="Size"></v-radio>
              </v-radio-group>
            </transition>
            <v-radio
              off-icon="mdi-checkbox-blank-outline"
              on-icon="mdi-checkbox-marked"
              color="primary"
              value="options"
              label="Option"
              @change="turnOffCreate()"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <!-- là bảng create Option -->
      <div v-if="filterType == 'options' && turnOnCreate">
        <v-row class="" v-for="(item, ind) in listOption" :key="ind">
          <v-col align-self="center" sm="2">Option Name:</v-col>
          <v-col align-self="center" sm="3"
            ><v-text-field
              placeholder="Longer than 3 characters"
              hide-details=""
              class="mb-0"
              v-model="listOption[ind].optionName"
            ></v-text-field
          ></v-col>
          <v-col align-self="center" sm="1">Value:</v-col>
          <v-col align-self="start" sm="5">
            <v-combobox
              @change="clearSpace(item)"
              v-model.trim="listOption[ind].value"
              hide-selected
              multiple
              persistent-hint
              small-chips
              placeholder="Required"
              hide-details
              append-icon=""
              :delimiters="[',']"
            >
              <template v-slot:selection="data">
                <v-chip
                  class="mb-2"
                  close
                  small
                  label
                  :key="data.item"
                  :disabled="data.disabled"
                  @click:close="data.parent.selectItem(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          <v-col align-self="center" sm="1"
            ><v-btn @click="removeOption(ind)" class="primary"><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn></v-col
          >
        </v-row>
        <v-row class="">
          <v-col sm="2"
            ><v-btn v-if="filterType == 'options'" color="primary" @click="addOptionName()">Add more +</v-btn></v-col
          >
        </v-row>
      </div>
      <!-- là cái bảng value ở dưới -->
      <!-- value color -->
      <v-row
        no-gutters
        class="mt-5 mx-0"
        v-if="filterType == 'variants' && variantFilterType == 'Color' && turnOnCreate"
      >
        <v-col sm="12"><h3>Value</h3></v-col>
        <v-divider></v-divider>
      </v-row>
      <transition name="slide-value">
        <div v-if="filterType == 'variants' && variantFilterType == 'Color' && turnOnCreate">
          <transition-group name="list-value">
            <!--transition group không nhận key == index -->
            <v-row v-for="(item, ind1) in dataColor" :key="ind1 + '1'">
              <v-col sm="1"> {{ ind1 + 1 }} </v-col>
              <!-- :style="`border: 1px solid ${item.color}`" -->
              <v-col sm="1"
                ><v-icon
                  v-if="item.value != 'Colorful'"
                  :color="item.code"
                  :class="item.value == 'White' ? 'border' : ''"
                  >mdi-checkbox-blank-circle</v-icon
                ><v-img
                  v-else
                  class="colorful"
                  src="https://minio.lattehub.com/img/200/200/resize/610e57f8d7768100099c00e5/2021/08/13/lattehub-image-6115ec57e9ff659c2b811362.jpg"
                ></v-img
              ></v-col>
              <v-col sm="2">{{
                item.value ? utilities.stringLimit(item.value, 17) : utilities.stringLimit(item.value, 20)
              }}</v-col>
              <v-col sm="1" class="text-center btn" @click="deleteColorData(ind1)"
                ><v-btn class="primary"><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn></v-col
              >
            </v-row>
          </transition-group>
          <v-btn class="mb-5" color="primary" @click="openEditColor()">Add Value</v-btn>
        </div>
      </transition>
      <!-- value size -->
      <v-row
        no-gutters
        class="mt-5 mx-0"
        v-if="filterType == 'variants' && variantFilterType == 'Size' && turnOnCreate"
      >
        <v-col sm="12"><h3>Value</h3></v-col>
        <v-divider></v-divider>
      </v-row>
      <transition name="slide-value">
        <div v-if="filterType == 'variants' && variantFilterType == 'Size' && turnOnCreate">
          <transition-group name="list-value">
            <!--transition group không nhận key == index -->
            <v-row v-for="(item, ind2) in dataSize1" :key="ind2 + '1'">
              <v-col sm="1"> {{ ind2 + 1 }} </v-col>
              <!-- :style="`border: 1px solid ${item.color}`" -->
              <v-col sm="1"
                ><strong>{{
                  item.title ? utilities.stringLimit(item.title, 17) : utilities.stringLimit(item, 20)
                }}</strong></v-col
              >
              <v-col sm="2" class="text-center btn" @click="deleteSizeData(ind2)"
                ><v-btn class="primary"><v-icon>mdi mdi-delete mdi-18px</v-icon></v-btn></v-col
              >
            </v-row>
          </transition-group>
          <v-btn class="mb-5" color="primary" @click="openEditSize()">Add Value</v-btn>
        </div>
      </transition>
    </div>
    <!-- Xuất hiện khi click btn Edit ở Variant Color -->
    <v-dialog class="pa-5" width="550" v-model="turnOnEditColor">
      <v-card class="px-5 py-5">
        <v-card-title class="pl-0 pt-0">
          Add Value
        </v-card-title>
        <div class="close-icon" @click="turnOnEditColor = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <div>Color Name</div>
        <v-text-field v-model="colorName"></v-text-field>
        <div>HEX</div>
        <!-- {{ colors.hex }} -->
        <v-text-field :background-color="colors.hex" v-model="colors.hex" readonly></v-text-field>
        <v-card-subtitle>
          <div class="sketch-color">
            <sketch-picker style="margin:auto" @input="updateValue" v-model="colors" />
          </div>
        </v-card-subtitle>
        <v-card-actions
          ><v-spacer></v-spacer>
          <v-btn color="primary" @click="addColorData(colorName, colors.hex)">Save</v-btn></v-card-actions
        >
      </v-card>
      <!-- <v-card class="px-5 py-5">
        <v-card-title class="pl-0 pt-0">
          Add Value
        </v-card-title>
        <div class="close-icon" @click="turnOnEditColor = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-row no-gutters>
          <v-col sm="8">
            <v-combobox
              dense
              v-model.trim="modelDataColorEdit"
              :items="dataColorEdit"
              chips
              placeholder="Click here"
              multiple
              :delimiters="[',']"
              @change="checkEmptyColor()"
            >
              <template v-slot:selection="data">
                <v-chip
                  class="mb-2"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click:close="removeColor(data.item, modelDataColorEdit)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addColorData(modelDataColorEdit)">Save</v-btn>
        </v-card-actions>
      </v-card> -->
    </v-dialog>
    <!-- Xuất hiện khi click btn Edit ở Variant Size -->
    <v-dialog width="550" v-model="turnOnEditSize">
      <v-card class="px-5 py-5">
        <v-card-title class="pl-0 pt-0">Add Value</v-card-title>
        <div class="close-icon" @click="turnOnEditSize = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-row no-gutters>
          <v-col sm="8">
            <v-combobox
              dense
              v-model.trim="modelDataSizeEdit"
              :items="dataSizeEdit"
              chips
              placeholder="Click here"
              multiple
              :delimiters="[',']"
              @change="checkEmptySize()"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click:close="removeColor(data.item, modelDataSizeEdit)"
                >
                  <!-- {{ utilities.stringLimit(data.item, 10) }} -->
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="addSizeData(modelDataSizeEdit)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- nút Create ở dưới -->
    <v-row class="mt-1 justify-end">
      <v-col class="text-right" sm="2">
        <v-btn
          color="primary"
          v-if="!changeToSave"
          :disabled="filterType == 'options' || variantFilterType != '' ? false : true"
          @click="onCreate()"
          >Create</v-btn
        >
        <v-btn color="primary" :disabled="checkDisable" @click="onSave()" v-if="changeToSave">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { menuFilterApi } from '@/apis/menufilter';
import utilities from '@/helpers/utilities';

export default {
  data() {
    return {
      colors: {},
      colorName: '',
      defaultData: {},
      isShowSelectionColor: false,
      utilities,
      // để thay đổi giữa Save và Create
      changeToSave: false,
      turnOnCreate: false,
      filterType: '',
      variantFilterType: '',
      dataSize1: ['S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'],
      dataColor1: [
        'Brown',
        'Yellow',
        'White',
        'Black',
        'Blue',
        'Grey',
        'Violet',
        'Pink',
        'Red',
        'Beige',
        'Green',
        'Orange',
        'Colorful',
      ],
      dataColor: [
        {
          code: '#A52A2A',
          value: 'Brown',
        },
        {
          code: '#FFFF00',
          value: 'Yellow',
        },
        {
          code: '#FFFFFF',
          value: 'White',
        },
        {
          code: '#000000',
          value: 'Black',
        },
        {
          code: '#0000FF',
          value: 'Blue',
        },
        {
          code: '#808080',
          value: 'Grey',
        },
        {
          code: '#EE82EE',
          value: 'Violet',
        },
        {
          code: '#FFC0CB',
          value: 'Pink',
        },
        {
          code: '#FF0000',
          value: 'Red',
        },
        {
          code: '#008000',
          value: 'Green',
        },
        {
          code: '#F5F5DC',
          value: 'Beige',
        },
        {
          code: '#FFA500',
          value: 'Orange',
        },
        {
          code: '#2EFE2E',
          value: 'Colorful',
        },
      ],
      modelDataColorEdit: [], // lưu các color value đã đc select ở dialog
      // mảng hiển thị các color value sẽ hiển thị ( để select ) ở dialog
      // dataColorEdit: [],
      modelDataSizeEdit: [], // lưu các size value đã đc select ở dialog
      dataSizeEdit: [], // mảng hiển thị các color value sẽ hiển thị ( để select ) ở dialog
      // là mảng filter menu ở màn index
      listFilter: [
        {
          title: '',
          avail: '',
          value: '',
        },
      ],
      // là mảng ở filter Option
      listOption: [
        {
          optionName: '',
          value: [],
        },
      ],
      turnOnEditColor: false, // để hiển thị dialog edit color
      turnOnEditSize: false, // để hiển thị dialog edit size
    };
  },
  methods: {
    updateValue() {
      this.defaultData.fontColor = this.colors.hex;
    },
    convertColor(item) {
      let result;
      this.dataColor.filter(i => {
        if (i.title == item) {
          result = i.color;
        }
      });
      return result;
    },
    // checkEmptyColor() {
    //   clear giá trị rỗng
    //   this.modelDataColorEdit = this.modelDataColorEdit.filter(i => i.trim() != '');
    //   để tránh việc thêm trùng size, color kiểu như Black và blAck
    //   let dataColor1UpperCase = this.dataColor1.map(i => i.toUpperCase());
    //   this.modelDataColorEdit = this.modelDataColorEdit.filter(i => !dataColor1UpperCase.includes(i.toUpperCase()));
    // },
    checkEmptySize() {
      this.modelDataSizeEdit = this.modelDataSizeEdit.filter(i => i.trim() != '');
      let dataSize1UpperCase = this.dataSize1.map(i => i.toUpperCase());
      this.modelDataSizeEdit = this.modelDataSizeEdit.filter(i => !dataSize1UpperCase.includes(i.toUpperCase()));
    },
    // dùng để remove cho cả size và color
    removeColor(item, model) {
      let index = model.indexOf(item);
      console.log(index);
      if (index > -1) {
        model.splice(index, 1);
      }
    },
    // khi Save để redirect lại màn index
    async onSave() {
      try {
        this.changeToSave = false;
        this.turnOnCreate = false;
        // console.log(params);
        setTimeout(async () => {
          let params = {
            attributes: [
              {
                name: '',
                type: '',
                values: '',
              },
            ],
          };
          if (this.filterType == 'variants') {
            params.attributes[0].name = this.variantFilterType;
            params.attributes[0].type = 'variants';
            if (this.variantFilterType == 'Color') {
              let obj = Object.assign(this.dataColor, {});
              params.attributes[0].values = obj;
            }
            if (this.variantFilterType == 'Size') {
              let arr = [];
              this.dataSize1.forEach(i => {
                arr.push({ value: i });
              });
              params.attributes[0].values = arr;
            }
          }
          // console.log(params);
          if (this.filterType == 'options') {
            for (let index = 0; index < this.listOption.length; index++) {
              if (index > 0) {
                params.attributes.push({
                  name: '',
                  type: '',
                  values: '',
                });
              }
              params.attributes[index].name = this.listOption[index].optionName;
              params.attributes[index].type = 'options';
              let arr = [];
              if (this.listOption[index].value != '') {
                this.listOption[index].value.forEach(i => {
                  arr.push({ value: i });
                });
                params.attributes[index].values = arr;
              }
            }
          }
          params.position = 2;
          await menuFilterApi.postFilter(params);
          this.listOption = [
            {
              optionName: '',
              value: '',
            },
          ];
          this.$router.push({ name: 'website.menufilter' });
        }, 10);
      } catch (error) {
        console.log(error);
      }
    },
    // để lọc space và những value giống nhau + thêm nhiều space phía sau. ex: 1, 1    , 1      ,
    clearSpace(item) {
      item.value = item.value.filter(i => i.trim() != '');
      let arr = [];
      for (let index = 0; index < item.value.length; index++) {
        let element = item.value[index];
        if (!arr.includes(element.trim())) {
          arr.push(element.trim());
        }
      }
      item.value = arr.map(x => x);
    },
    // 2 method trigger khi click Edit
    openEditColor() {
      this.turnOnEditColor = true;
    },
    openEditSize() {
      this.turnOnEditSize = true;
    },
    // trigger khi click icon thùng rác
    deleteColorData(colors) {
      // this.dataColorEdit.push(this.dataColor1[colors]);
      this.dataColor.splice(colors, 1);
    },
    // khi click Save ở dialog
    addColorData(colorName, colorCode) {
      // if (colors.length > 0) {
      //   colors là cái v-model lúc select (ở dialog), push cả cái obj mà có color == v-model vào list muốn thêm ban đầu
      //   colors.forEach(item => {
      //     let position = this.dataColorEdit.findIndex(i => i == item);
      //     if (position > -1) {
      //       push vào table hiển thị màu
      //       this.dataColor1.push(this.dataColorEdit[position]);
      //       cắt bỏ những màu đã thêm khỏi list màu Edit
      //       this.dataColorEdit.splice(position, 1);
      //     } else {
      //       this.dataColor1.push(item);
      //     }
      //   });
      //   this.modelDataColorEdit = '';
      //   this.turnOnEditColor = false;
      // }
      let a = {
        code: colorCode,
        value: colorName,
      };
      this.dataColor.push(a);
      this.colorName = '';
      this.colors.hex = '';
      this.turnOnEditColor = false;
    },
    // trigger khi click icon thùng rác
    deleteSizeData(size) {
      this.dataSizeEdit.push(this.dataSize1[size]);
      this.dataSize1.splice(size, 1);
    },
    // khi click Save ở dialog
    addSizeData(sizes) {
      // colors là cái v-model lúc select (ở dialog), push cả cái obj mà có color == v-model vào list muốn thêm ban đầu
      sizes.forEach(item => {
        let position = this.dataSizeEdit.findIndex(i => i == item);
        if (position > -1) {
          // push vào table hiển thị màu
          this.dataSize1.push(this.dataSizeEdit[position]);
          // cắt bỏ những màu đã thêm khỏi list màu Edit
          this.dataSizeEdit.splice(position, 1);
        } else {
          this.dataSize1.push(item);
        }
      });
      this.modelDataSizeEdit = '';
      this.turnOnEditSize = false;
    },
    // khi click Create
    onCreate() {
      this.turnOnCreate = true;
      this.changeToSave = true;
    },
    // để mỗi khi chuyển giữa color và size thì
    turnOffCreate() {
      this.turnOnCreate = false;
    },
    removeOption(ind) {
      this.listOption.splice(ind, 1);
    },
    addOptionName() {
      this.listOption.push({
        optionName: '',
        value: '',
      });
      console.log(this.listOption);
    },
  },
  watch: {
    filterType() {
      this.changeToSave = false;
    },
    variantFilterType() {
      this.changeToSave = false;
    },
  },
  computed: {
    checkDisable() {
      let result = true;
      if (this.filterType == 'options') {
        let a = 0;
        for (let index = 0; index < this.listOption.length; index++) {
          const element = this.listOption[index];
          if (element.optionName.length > 2 && element.value.length > 0) {
            a += 1;
          }
        }
        if (a == this.listOption.length) {
          result = false;
        }
      }
      if (this.filterType == 'variants' && this.variantFilterType == 'Color') {
        if (this.dataColor.length > 0) {
          result = false;
        }
      }
      if (this.filterType == 'variants' && this.variantFilterType == 'Size') {
        if (this.dataSize1.length > 0) {
          result = false;
        }
      }
      return result;
    },
  },
};
</script>

<style>
.btn {
  cursor: pointer;
}
.list-value-enter-active,
.list-value-leave-active {
  transition: all 0.3s;
}
.list-value-enter, .list-value-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-30px);
}
.slide-key-enter-active,
.slide-key-leave-active {
  transition: all 0.8s ease;
}

.slide-key-enter,
.slide-key-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.slide-value-enter-active,
.slide-value-leave-active {
  transition: all 1s ease;
}

.slide-value-enter,
.slide-value-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
.close-icon {
  position: absolute;
  top: 16px;
  right: 10px;
  cursor: pointer;
}
.border {
  border: 1px solid #e6e6e6;
  border-radius: 100%;
}
.colorful {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  margin-left: 1px;
}
</style>
